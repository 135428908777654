/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/js/all.js';

import '../../scss/front/app.scss';
import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-slider';
import 'bootstrap-3-typeahead';
import 'ekko-lightbox';
import 'jquery.cookie';
import routes from '../../../public/js/fos_js_routes.json';
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';




import breakpoints from './_breakpoint-bs4';
//import navToggler from './_navToggler';
//import carousel from './_carousel';
import form from './_form';
import datepicker from './_datepickerWidget';
import slide from './_slideWidget';
import svg from './_svg';
import smoothScroll from './_smoothScroll';
import modal from './_modal';
import collapse from './_collapse';
import select2 from '../front/_select2Widget';
import updateForm from '../front/_updateForm-Widget';
import autocomplete from '../front/_autocomplete';
import lightbox from '../front/_lightbox';
import map from '../front/_map';
import backToTop from '../front/_backToTop';
//import header from '../front/_header';
import region from '../front/_region';
import cookie from '../front/_cookie';

/*global window */
(function (window, $, document) {
    'use strict';
    let initialize = function (context) {
        //navToggler(breakpoints);
        //carousel(breakpoints);
        form(context);
        datepicker(context);
        slide(context);
        svg();
        smoothScroll();
        modal();
        collapse();
        select2();
        updateForm();
        autocomplete(Routing);
        lightbox();
        map();
        //backToTop();
       // header(breakpoints);
        region(breakpoints);
        cookie();
    };
    $(document).on('dune_load', function (event) {
        initialize(event.currentTarget);
    });

    $(function () {
        initialize(document);
    });
})(window, window.jQuery, window.document);


