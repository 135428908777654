var form = function (context) {
    $("label[data-check='dune']").click(function () {
        var card = $(this).closest('.card-body');
        if (card.hasClass('bg-color-white')) {
            card.removeClass('bg-color-white');
        } else {
            card.addClass('bg-color-white');
        }
    });
    $("input[id='newsletter_email']").click(function () {
        $('.newsletter-form').addClass('d-flex');
    });
    $("form div.counter div input").before('<div class="dec counter-button"><i class="fas fa-minus"></i></div>').after('<div class="inc counter-button"><i class="fas fa-plus"></i></div>');

    $(".counter-button").on("click", function(e) {
        e.stopPropagation();
        var $button = $(this);
        var oldValue = $button.parent().find("input").val();

        if ($button.hasClass('inc')) {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }

        $button.parent().find("input").val(newVal);

    });
};


export default form;