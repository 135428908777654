import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min.js';

$('.heroBannerSlider').slick({
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000
});
$('.testimonialsSlider').slick({
  dots: true,
    autoplay: true,
    autoplaySpeed: 5000
});
$('.destinationsFavoriteSlider').slick({
    arrows: true,
    slidesToShow: 3,
    infinite: true,
    autoplay: true,
    slidesToScroll: 2,
    autoplaySpeed: 7000,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
        },
    ]
});
$('.essentialsSlider').slick({
  arrows: true,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 7000,
  slidesToScroll: 1,
  centerMode: true,
  // variableWidth: true,
  // adaptiveHeight: true,
  // speed: 0,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        centerMode: false,
        arrows: false,
        variableWidth: false,
        slidesToShow: 2.8,
        infinite: false,
      }
    },
    {
      breakpoint: 576,
      settings: {
        centerMode: false,
        arrows: false,
        variableWidth: false,
        slidesToShow: 1.2,
        infinite: false,
      }
    },
  ]
});

$('.tipsSlider').slick({
  arrows: true,
  slidesToShow: 1,
  infinite: false,
  adaptiveHeight: true,
  dots: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        dots: false
      }
    },
  ]
});

let planningTabs = document.getElementById('programmes');
document.querySelectorAll('.nav-link').forEach(linkItem => {
  linkItem.addEventListener('click', _ => {
    let planningTabsTop = planningTabs.offsetTop;
    window.scrollTo({ top: planningTabsTop - 50, left: 0, behavior: "smooth" })
  })
})
