var collapse = function () {
    $('.multi-collapse').on("click", function(e) {
        var cols = $('div[id^=' + $(this).attr('href') +']');
        if (cols.first().hasClass('show')) {
            cols.collapse('hide')
        } else {
        cols.collapse('show')
        }
    })
};

export default collapse;