/*global window */
'use strict';
var select2Handler = function () {
    $('.select2').select2({
        theme: "dune",
        "width": "100%",
    });
};

export default select2Handler;

