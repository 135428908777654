/*global window */
'use strict';

var datepickerHandler = function () {
    $('.slide-picker').slider({
        formatter: function(value) {
            return value + " €";
        }
    });
};

export default datepickerHandler;

