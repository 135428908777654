import './../../styles/reset.css';
import './../../styles/style.css';
import './../../styles/navigation.css';
import './../../styles/stats-payments.css';
import './../../styles/newsletter.css';

import './_carouselSlick.js';

var region = function (breakpoints) {
    var carousel = $('#carouselTravelRegion');
    var items = carousel.find('.carousel-item');
    if ($(window).width() < breakpoints[2]) {
        carousel
            .on('slide.bs.carousel', function () {
                var id = $('#carouselTravelRegion .carousel-item.active').data('slide-target');
                $('.item-indicator[data-slide=' + id + ']').removeClass('item-color-corail');
            })
            .on('slid.bs.carousel', function () {
                var id = $('#carouselTravelRegion .carousel-item.active').data('slide-target');
                $('.item-indicator[data-slide=' + id + ']').addClass('item-color-corail');
            })
    }
};

export default region;