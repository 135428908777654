/*global window */
'use strict';

/**
 * @param {Object} element
 * @constructor
 */
var updateFormWidget = function (element) {
    this.element = element;
    this.$element = $(element);
    this.init();
};

updateFormWidget.prototype = {};

var updateForm = function (context) {
    $(document).on('change', 'select[id$=_destination]', function () {
        // ... retrieve the corresponding form.
        var $form = $(this).closest('form');
        // Simulate form data, but only include the selected sport value.
        var data = {};
        data[$(this).attr('name')] = $(this).val();
        // Submit data via AJAX to the form's action path.
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: data,
            success: function (html) {
                var $select = $form.find('select[id$=_product]');
                var $id = $select.attr('id');
                // Replace current position field ...
                $select.replaceWith(
                    // ... with the returned one from the AJAX response.
                    $(html).find('#' + $id)
                );
                $('#' + $id).select2({
                    theme: "dune",
                    "width": "100%",
                });
                // Position field now displays the appropriate positions.
            }
        });
    });
    $(document).on('change', 'select[id$=_product]', function () {
        // ... retrieve the corresponding form.
        var $form = $(this).closest('form');

        $form.find('#group-extras').show();
        // Simulate form data, but only include the selected sport value.
        var data = {};
        data[$(this).attr('name')] = $(this).val();
        console.log(data);
        // Submit data via AJAX to the form's action path.
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: data,
            success: function (html) {
                var $select = $form.find('select[id$=_extras]');
                var $id = $select.attr('id');
                var $newSelect = $(html).find('#' + $id);
                console.log($newSelect.options);
                // Replace current position field ...
                $select.replaceWith(
                    // ... with the returned one from the AJAX response.
                    $newSelect
                );
                $newSelect.select2({
                    theme: "dune",
                    "width": "100%",
                });
                // Position field now displays the appropriate positions.
            }
        });
    });
};
module.exports = updateForm;
