var autocomplete = function (Routing) {
    var input = $('#search-input');
    var inputHeader = $('#search-input-header');
    var suggestUrl = input.data('suggest');

    input.typeahead({
        minLength: 2, // will start autocomplete after 2 characters
        items: 5, // will display 5 suggestions
        highlighter: function (item) {
            var elem = this.reversed[item];
            var html = '<div class="typeahead">';

            if (elem.name) {
                html += '<div class="suggestion"><a href="#">' + elem.name + '</a></div>';
            }

            html += '</div>';

            return html;
        },
        source: function (query, process) {
            // "query" is the search string
            // "process" is a closure which must receive the suggestions list

            var $this = this;
            $.ajax({
                url: suggestUrl,
                type: 'GET',
                data: {
                    q: query
                },
                success: function (data) {
                    //  "name" is the string to display in the suggestions

                    // this "reversed" array keep a temporarly relation between each suggestion and its data
                    var reversed = {};

                    // here we simply generate the suggestions list
                    var suggests = [];

                    $.each(data, function (id, elem) {
                        reversed[elem.name] = elem;
                        suggests.push(elem.name);
                    });

                    $this.reversed = reversed;
                    // displaying the suggestions
                    process(suggests);
                }
            })
        },
        // this method is called when a suggestion is selected
        updater: function (item) {
            // do whatever you want
            //console.log(item);
            var elem = this.reversed[item];
            /*switch (elem.class) {
                case 'App\\Entity\\Destination':
                    window.location.replace(Routing.generate('destination', {'slug':elem.slug, 'query': elem.query}));
                    break;
                case 'App\\Entity\\Region':
                    window.location.replace(Routing.generate('region', {'slug':elem.slug, 'query': elem.query}));
                    break;
                case 'App\\Entity\\Product':
                    window.location.replace(Routing.generate('product', {'destination':elem.destinationSlug, 'slug':elem.slug, 'query': elem.query}));
                    break;
                case 'App\\Entity\\Cruse':
                    window.location.replace(Routing.generate('product', {'destination':elem.destinationSlug, 'slug':elem.slug, 'query': elem.query}));
                    break;
                case 'App\\Entity\\Stay':
                    window.location.replace(Routing.generate('product', {'destination':elem.destinationSlug, 'slug':elem.slug, 'query': elem.query}));
                    break;
                case 'App\\Entity\\Safari':
                    window.location.replace(Routing.generate('product', {'destination':elem.destinationSlug, 'slug':elem.slug, 'query': elem.query}));
                    break;
                case 'App\\Entity\\News':
                    window.location.replace(Routing.generate('news', {'slug':elem.slug, 'query': elem.query}));
                    break;
                default:
                    return true;
            }*/
            window.location.href=elem.url;
            return elem; // this return statement fills the input with the selected string
        },
        // this method determines which of the suggestions are valid. We are already doing all this server side, so here just
        // return "true"
        matcher: function () {
            return true;
        }
    });
    inputHeader.typeahead({
        minLength: 2, // will start autocomplete after 2 characters
        items: 5, // will display 5 suggestions
        highlighter: function (item) {
            var elem = this.reversed[item];
            var html = '<div class="typeahead">';

            if (elem.name) {
                html += '<div class="suggestion"><a href="test">' + elem.name + '</a></div>';
            }

            html += '</div>';

            return html;
        },
        source: function (query, process) {
            // "query" is the search string
            // "process" is a closure which must receive the suggestions list

            var $this = this;
            $.ajax({
                url: suggestUrl,
                type: 'GET',
                data: {
                    q: query
                },
                success: function (data) {
                    //  "name" is the string to display in the suggestions

                    // this "reversed" array keep a temporarly relation between each suggestion and its data
                    var reversed = {};

                    // here we simply generate the suggestions list
                    var suggests = [];

                    $.each(data, function (id, elem) {
                        reversed[elem.name] = elem;
                        suggests.push(elem.name);
                    });

                    $this.reversed = reversed;
                    // displaying the suggestions
                    process(suggests);
                }
            })
        },
        // this method is called when a suggestion is selected
        updater: function (item) {
            // do whatever you want
            var elem = this.reversed[item];
            /*switch (elem.class) {
                case 'App\\Entity\\Destination':
                    window.location.replace(Routing.generate('destination', {'slug':elem.slug}));
                    break;
                case 'App\\Entity\\Region':
                    window.location.replace(Routing.generate('region', {'slug':elem.slug}));
                    break;
                case 'App\\Entity\\Product':
                    window.location.replace(Routing.generate('product', {'destination':elem.destinationSlug, 'slug':elem.slug}));
                    break;
                case 'App\\Entity\\News':
                    window.location.replace(Routing.generate('news', {'slug':elem.slug}));
                    break;
                default:
                    return true;
            }
            //window.location.replace();*/
            window.location.href=elem.url;
            return elem; // this return statement fills the input with the selected string
        },
        // this method determines which of the suggestions are valid. We are already doing all this server side, so here just
        // return "true"
        matcher: function () {
            return true;
        }
    });

};

export default autocomplete;
