var map = function () {
    $('.card-map').each(function(){
        if ($(this).hasClass('bl')) {
            $(this).css({'top':'-20px','left':'-60px'});
        } else if ($(this).hasClass('br')) {
            $(this).css({'top':'-20px','left':'-60px'});
        }  else if ($(this).hasClass('tl')) {
            $(this).css({'top':'-20px','left':'-60px'});
        }  else if ($(this).hasClass('tr')) {
            $(this).css({'top':'-20px','left':'-60px'});
        }
    });
};
export default map;
/*
var map = function () {
    $('.card-map').each(function(){
        if ($(this).hasClass('bl')) {
            $(this).css({'top':'30px','left':'-' + ($(this).width() + 15) + 'px'});
        } else if ($(this).hasClass('br')) {
            $(this).css({'top':'30px','left':'30px'});
        }  else if ($(this).hasClass('tl')) {
            $(this).css({'top':'-' + $(this).height() + 'px','left':'-' + ($(this).width() + 15) + 'px'});
        }  else if ($(this).hasClass('tr')) {
            $(this).css({'top':'-' + $(this).height() + 'px','left':'30px'});
        }
    });
};
export default map;*/

